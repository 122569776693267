import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import createRelayEnvironment from 'relay/createRelayEnvironment';
import { RelayNodeSubscriber } from 'relay/useLiveFragment';
import { useAccessToken } from './AuthenticationProvider/AuthenticationProvider';

const RelayProvider: React.FC<{ locale?: string; children: React.ReactNode; records?: RecordMap }> =
  function RelayProvider({ locale, children, records }) {
    const getAccessToken = useAccessToken();
    const oldRecords = React.useRef(records);
    const [environment] = React.useState(() => {
      return createRelayEnvironment({
        getAccessToken,
        onError: (err) => console.warn(err),
        records,
        locale: locale,
      });
    });
    // We want to update the env on the render thread to not give relay a chance of fetching the data before it was added to the store
    const currentEnvironment = React.useRef(environment);
    if (records !== oldRecords.current) {
      const currentJSONStore = currentEnvironment.current.getStore().getSource().toJSON();
      // TODO: Find a better supported way of merging these stores
      const mergedStore = {
        ...currentJSONStore,
        ...records,
        'client:root': {
          ...currentJSONStore['client:root'],
          ...((records && records['client:root']) || {}),
        },
      };
      oldRecords.current = records;
      currentEnvironment.current = createRelayEnvironment({
        getAccessToken: getAccessToken,
        onError: (err) => console.warn(err),
        records: mergedStore,
        locale: locale,
      });
    }

    return (
      <RelayEnvironmentProvider environment={currentEnvironment.current}>
        <RelayNodeSubscriber />
        {children}
      </RelayEnvironmentProvider>
    );
  };
export default RelayProvider;
